<template>
    <div>
        <h1 class="editorHeader">
            New Member Management
        </h1>
        <div>
            <table class="table table-hover table-dark">
                <thead>
                    <tr class="tableHeadingContainer">
                        <th scope="col" class="tableHeading col-xs-2">Name</th>
                        <th scope="col" class="tableHeading mobile col-xs-3">Email</th>
                        <th scope="col" class="tableHeading xs-screen col-xs-2">Age Group</th>
                        <th scope="col" class="tableHeading xs-screen col-xs-2">Gender</th>
                        <th scope="col" class="tableHeading action col-xs-3">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="member in members" :key="member._id">
                        <td class="col-xs-2">
                            <router-link :to="`/members/newMembers/${member.name}`">{{member.name}}</router-link>
                        </td>
                        <td class="col-xs-3 mobile">
                            {{member.email}}
                        </td>
                        <td class="xs-screen col-xs-2">
                            {{ member.ageGroup }}
                        </td>
                        <td class="xs-screen col-xs-2">
                            {{ member.gender }}
                        </td>
                        <td class="col-xs-3">
                            <b-button @click="acceptMember(member._id, member)" class="btn accept">Accept</b-button>
                            <b-button @click="declineMember(member._id)" class="btn decline">Decline</b-button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="applicant-container">
            <router-view/>
        </div>
    </div>
</template>

<script>
    import MemberService from "../../../services/memberService";
    import Vue from "vue";

    export default {
        name: "NewMember",
        data() {
            return {
                members: [],
            }
        },
        methods: {
            renderMembers() {
                this.memberService.findAllNewMembers(Vue.$cookies.get('user')).then((members) => {
                    this.members = members;
                });
            },
            acceptMember(id, member) {
                if(window.confirm("Are you sure you want to accept this member?")) {
                    this.memberService.acceptMember(Vue.$cookies.get('user'), id, member).then(() => {
                        this.renderMembers()
                    }).catch(() => {
                        alert("Something went wrong, please try again");
                        this.renderMembers();
                    })
                }
            },
            declineMember(id) {
                if(window.confirm("Are you sure you want to DECLINE this member?")) {
                    this.memberService.declineMember(Vue.$cookies.get('user'), id).then(() => {
                        this.renderMembers()
                    }).catch(() => {
                        alert("Something went wrong, please try again");
                        this.renderMembers();
                    })
                }
            }
        },
        mounted() {
            this.memberService = MemberService.getInstance();
            this.memberService.findAllNewMembers(Vue.$cookies.get('user')).then((members) => {
                this.members = members;
            });
        }
    }
</script>

<style scoped>
    .accept {
        width: 49%;
        background-color: blue;
    }

    .decline {
        width: 49%;
        background-color: red;
        margin-left: 2%;
    }

    .action{
        text-align: center;
    }

    .applicant-container {
        text-align: left;
    }

    @media (max-width: 850px) {
        .small-screen  {
            display: none;
        }
    }
    @media (max-width: 700px) {
        .xs-screen  {
            display: none;
        }
    }
    @media (max-width: 500px) {
        .mobile  {
            display: none;
        }
    }

</style>
